var $ = require("jquery");
require("../../node_modules/waypoints/lib/noframework.waypoints.js");

// Turbolinks
var Turbolinks = require("turbolinks");
Turbolinks.start();

$(document).ready(function(){
	// Toggling classes
	$('.nav-icon').click(function(){
		$(this).toggleClass('open');
		$('.mobile-menu').toggleClass('active'); 
	});

	// Waypoints
	if ($(window).width() > 992) {
		new Waypoint({
			element: document.getElementById("js_sidebar_top"),
			handler: function(direction) {
				$(".side-bar").toggleClass("fixed");
				$(".col-8").toggleClass("adjust");
			}
		});
		new Waypoint({
			element: document.getElementById("js_sidebar_bottom"),
			handler: function(direction) {
				$(".side-bar").toggleClass('bottom');
			},
			offset: '20'
		});
	}

	// Animated anchor jumps
	$('.animate .this').on('click', function(e) {
		e.preventDefault();

		var $this = $(this);
		var $element = $('#' + $this.attr('href'));

		$('html, body').animate({
			scrollTop: $element.offset().top - 47
		})
	});
});